import { SagaIterator } from "@redux-saga/core";
import { call, delay, put, select, takeEvery } from "redux-saga/effects";
import { CancelDocument } from "../types";

// API
import {
  directoryDetails, getIpInfo, registerFaceID, signatureDefault, signsecureApproveDocument,
  signsecureCancelDocument, signsecureCompleteDocument, signsecureCreateDocument,
  signsecureDeclineDocument,
  signsecureDirectoryDownload,
  signsecureDownloadDocument, signsecureEncryptPDF, signsecureFinalDraftDocument, signsecureImportDocument, signsecureLockDocument, signsecurePatchDraftDocument, signsecureRejectDocument,
  signsecureSaveDocument,
  signsecureSignDocument, signsecureVerifyUser, signsecureViewDocument
} from "@src/utils/api";

//HELPER
import { CreateDocumentDataParser, EncryptPDFDocumentDataParser, ImportDocumentDataParser } from "@src/utils/formdata-helper";
import { completeSignatureWithCreateManifest, submitOnlyAffixSignature } from "@src/utils/signature-helper";

// Slice
import { checkEmailHasInListed, checkPartiesSigned } from "@src/utils/filter-helper";
import _ from "lodash";
import { authActions, selectAuthLoggedIn, selectDocumentOnFloat, selectedAuthAccessToken, selectedAuthEmail, selectedAuthLoginType } from "../slices/auth.slice";
import { selectedDocumentParties, selectedSignsecureInputs, signsecureActions } from "../slices/signsecure.slice";
import { userActions } from "../slices/user.slice";

function* handleCreateDocumentRequest(): SagaIterator {
  try {
    const ip = yield call(getIpInfo);
    const accessToken = yield select(selectedAuthAccessToken);
    const signsecureInput = yield select(selectedSignsecureInputs);
    const formdata = yield call(CreateDocumentDataParser, signsecureInput, ip);
    const isDraft = signsecureInput.files[0]?.isDraft;
    const result = yield call(isDraft ? signsecureFinalDraftDocument : signsecureCreateDocument, formdata, accessToken);

    yield put(signsecureActions.createDocumentRequestSuccess(result.data));
  
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    const failedMessage = message?.replace(/^HttpException:\s*/, "");

    yield put(signsecureActions.createDocumentRequestFailed({ message: failedMessage }));

    if (message.includes("Unauthorized")) {
      yield put(userActions.failed({ message: failedMessage }));
      yield put(authActions.logout());
    }
    yield delay(1000);
    yield put(signsecureActions.createDocumentRequestFailed({}));
  }
}

function* handleSaveDocumentRequest(): SagaIterator {
  try {
    const ip = yield call(getIpInfo);
    const accessToken = yield select(selectedAuthAccessToken);
    const signsecureInput = yield select(selectedSignsecureInputs);
    const formdata = yield call(CreateDocumentDataParser, signsecureInput, ip);
    const isDraft = signsecureInput.files[0]?.isDraft;
    const result = yield call(isDraft ? signsecurePatchDraftDocument : signsecureSaveDocument, formdata, accessToken);
        
    yield put(signsecureActions.saveDocumentRequestSuccess(result.data));
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    const failedMessage = message?.replace(/^HttpException:\s*/, "");

    yield put(signsecureActions.createDocumentRequestFailed({ message: failedMessage }));

    if (message.includes("Unauthorized")) {
      yield put(userActions.failed({ message: failedMessage }));
      yield put(authActions.logout());
    }
    yield delay(1000);
    yield put(signsecureActions.createDocumentRequestFailed({}));
  }
}

function* handleImportDocumentRequest(action: {
  type: typeof signsecureActions.importDocumentRequest;
  payload: any;
}): SagaIterator {
  try {
    const ip = yield call(getIpInfo);
    const accessToken = yield select(selectedAuthAccessToken);
    const formdata = yield call(ImportDocumentDataParser, action.payload, ip);
    const result = yield call(signsecureImportDocument, formdata, accessToken);
    
    yield put(signsecureActions.importDocumentRequestSuccess(result.data));
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    const failedMessage = message?.replace(/^HttpException:\s*/, "");

    yield put(signsecureActions.importDocumentRequestFailed({ message: failedMessage }));

    if (message.includes("Unauthorized")) {
      yield put(userActions.failed({ message: failedMessage }));
      yield put(authActions.logout());
    }
    yield delay(1000);
    yield put(signsecureActions.importDocumentRequestFailed({}));
  }
}

function* handleViewDocument(action: {
  type: typeof signsecureActions.viewDocumentRequest;
  payload: any;
}): SagaIterator {
  try {
    const email = yield select(selectedAuthEmail);
    const isLoggedIn = yield select(selectAuthLoggedIn);
    const accessToken = yield select(selectedAuthAccessToken);

    const verifyUser = yield call(
      signsecureVerifyUser,
      action.payload.id,
      accessToken
    );
    
    const checkEmailisInlist = yield call(
      checkEmailHasInListed,
      verifyUser.data,
      email
    );

    if(verifyUser.data.privacyType === "PUBLIC" && !checkEmailisInlist){
      const base64 = yield call(
        signsecureDirectoryDownload,
        action.payload.id,
        accessToken
      );
      yield put(signsecureActions.viewDocumentSuccess({...verifyUser.data, uri: base64}));
      return;
    }else if(verifyUser.data.privacyType === "RESTRICTED" && !checkEmailisInlist && isLoggedIn){
      const base64 = yield call(
        signsecureDirectoryDownload,
        action.payload.id,
        accessToken
      );
      yield put(signsecureActions.viewDocumentSuccess({...verifyUser.data, uri: base64}));
      return;
    }

    const base64 = yield call(
      signsecureDownloadDocument,
      action.payload.id,
      accessToken
    );


    const result = yield call(
      signsecureViewDocument,
      action.payload.id,
      accessToken
    );
    yield call(
      signsecureLockDocument,
      action.payload.id,
      accessToken
    );

    if(result.data?.workflow?.status === "CANCELLED"){
      yield put(signsecureActions.cancelDocumentSuccess(result.data));
    }
    yield put(signsecureActions.viewDocumentSuccess({...result.data, uri: base64}));
  } catch (error: any) {
    console.log("error", error);
    const message = error.message || error.error || "Something went wrong";
    const failedMessage = message?.replace(/^HttpException:\s*/, "");
    yield put(signsecureActions.viewDocumentFailed({ message: failedMessage }));
    
    if (message.includes("Unauthorized")) {
      yield put(userActions.failed({ message: failedMessage }));
      yield put(authActions.logout());
    }
    yield delay(1000);
    yield put(signsecureActions.viewDocumentFailed({}));
  }
}

function* handleUserQuickSigning(): SagaIterator {
  try {
    const email = yield select(selectedAuthEmail);
    const accessToken = yield select(selectedAuthAccessToken);
    const defaultSignature = yield call(signatureDefault, accessToken);
    const registerFaceid = yield call(registerFaceID, { email });

    if(defaultSignature.data === null){
      yield put(signsecureActions.enableQuickSigning(false));
    }else if(_.isEmpty(registerFaceid.data?.detections)){
      yield put(signsecureActions.enableQuickSigning(false));
    }else{
      yield put(signsecureActions.enableQuickSigning(true));
    }
  } catch (error: any) {
    yield put(signsecureActions.enableQuickSigning(false));
  }
}

function* handleAffixSignature(action: {
  type: typeof signsecureActions.affixSignatureRequest;
  payload: any;
}): SagaIterator {
  try {
    const loginType = yield select(selectedAuthLoginType);
    const accessToken = yield select(selectedAuthAccessToken);
    const currentParty = yield select(selectedDocumentParties);

    const locks = yield call(
      signsecureLockDocument,
      action.payload.id,
      accessToken
    );

    if(locks.data.isLocked){
      throw { message: "Please wait while other parties are signing/approving." };
    }

    yield put(authActions.documentOnFloat({...action.payload, documentStatus: "AFFIXING"}));

    const updatedUri = yield call(
      signsecureDownloadDocument,
      action.payload.id,
      accessToken
    );
    const base64 = yield call(
      submitOnlyAffixSignature,
      updatedUri,
      action.payload,
      currentParty
    );

    const ip = yield call(getIpInfo);

    // Create a Blob from the Uint8Array
    const blob = new Blob([base64], { type: "application/pdf" });
    const signature = action.payload.signature || {};
    // Append the Blob to the FormData
    const formData = new FormData();
    formData.append("file", blob, `${action.payload.name}.pdf`);
    formData.append("ip", ip);
    // formData.append("country", ip.country);
    // formData.append("regionCity", `${ip.regionName}/${ip.city}`);
    formData.append("loginType", loginType);
    formData.append("signatureType", signature.signatureType);
    formData.append("signatureId", signature.signatureId);

    const result: any = yield call(
      signsecureSignDocument,
      action.payload.id,
      formData,
      accessToken
    );

    const isCompleted = yield call(checkPartiesSigned, result.data);
    if(isCompleted){
      const uri = yield call(
        signsecureDownloadDocument,
        result.data.id,
        accessToken
      );

      let folderName = "";
      if(result.data.parent){
        const parent = yield call(
          directoryDetails,
          result.data.parent,
          accessToken
        );

        folderName = parent.data.name;
      }
      
      const base64_complete = yield call(
        completeSignatureWithCreateManifest,
        uri,
        {...result.data, folderName}
      );
      const newblob = new Blob([base64_complete], { type: "application/pdf" });
      const formDataComplete = new FormData();
      formDataComplete.append("file", newblob, `${result.data.name}.pdf`);
      const completed = yield call(
        signsecureCompleteDocument,
        result.data.id,
        formDataComplete,
        accessToken
      );
      //encrypt PDF
      if(completed.success){
        if(completed.data.password && completed.data.password.length !== 0){
            yield call(handlePDFEncryption, completed, newblob, accessToken);
       }
      }
      yield put(authActions.documentOnFloat({}));
      yield put(signsecureActions.affixSignatureSuccess(completed.data));
      return;
    }

    yield put(authActions.documentOnFloat({}));
    yield put(signsecureActions.affixSignatureSuccess(result.data));
  } catch (error: any) {
    console.log("error", error);
    const message = error.message || error.error || "Something went wrong";
    const failedMessage = message?.replace(/^HttpException:\s*/, "");
    yield put(signsecureActions.affixSignatureFailed({ message: failedMessage }));
    
    if (message.includes("Unauthorized")) {
      yield put(userActions.failed({ message: failedMessage }));
      yield put(authActions.logout());
    }
    yield delay(1000);
    yield put(signsecureActions.affixSignatureFailed({}));
  }
}

function* handleApproveDocument(action: {
  type: typeof signsecureActions.approveDocumentRequest;
  payload: any;
}): SagaIterator {
  try {
    const ip = yield call(getIpInfo);
    const loginType = yield select(selectedAuthLoginType);
    const accessToken = yield select(selectedAuthAccessToken);
    const locks = yield call(
      signsecureLockDocument,
      action.payload.id,
      accessToken
    );

    if(locks.data.isLocked){
      throw { message: "Please wait while other parties are signing/approving." };
    }
    
    const params = {
      id: action.payload.id,
      loginType: loginType,
      ip: ip,
      // country: ip.country,
      // regionCity: `${ip.regionName}/${ip.city}`,
      reason: "Approved."
    };
    yield put(authActions.documentOnFloat({...action.payload, documentStatus: "APPROVE"}));
    const result = yield call(signsecureApproveDocument, params, accessToken);
    const isCompleted = yield call(checkPartiesSigned, result.data);
    if(isCompleted){
      const uri = yield call(
        signsecureDownloadDocument,
        result.data.id,
        accessToken
      );
      let folderName = "";
      if(result.data.parent){
        const parent = yield call(
          directoryDetails,
          result.data.parent,
          accessToken
        );

        folderName = parent.data.name;
      }
      
      const base64_complete = yield call(
        completeSignatureWithCreateManifest,
        uri,
        {...result.data, folderName}
      );
      const newblob = new Blob([base64_complete], { type: "application/pdf" });
      const formDataComplete = new FormData();
      formDataComplete.append("file", newblob, `${result.data.name}.pdf`);
      const completed = yield call(
        signsecureCompleteDocument,
        result.data.id,
        formDataComplete,
        accessToken
      );
      //encrypt PDF
      if(completed.success){
        if(completed.data.password && completed.data.password.length !== 0){
            yield call(handlePDFEncryption, completed, newblob, accessToken);
        }
      }
      yield put(authActions.documentOnFloat({}));
      yield put(signsecureActions.approveDocumentSuccess(completed.data));
      return;
    }

    yield put(authActions.documentOnFloat({}));
    yield put(signsecureActions.approveDocumentSuccess(result.data));
  } catch (error: any) {
    console.log("error", error);
    const message = error.message || error.error || "Something went wrong";
    yield put(signsecureActions.approveDocumentFailed({ message }));

    if (message.includes("Unauthorized")) {
      yield put(userActions.failed({ message }));
      yield put(authActions.logout());
    }
    yield delay(1000);
    yield put(signsecureActions.approveDocumentFailed({}));
  }
}

function* handleCancelDocument(action: {
  type: typeof signsecureActions.cancelDocumentRequest;
  payload: CancelDocument;
}): SagaIterator {
  try {
    const accessToken = yield select(selectedAuthAccessToken);
    const result = yield call(
      signsecureCancelDocument,
      action.payload,
      accessToken
    );
    yield put(signsecureActions.cancelDocumentSuccess(result.data));
  } catch (error: any) {
    console.log("error", error);
    const message = error.message || error.error || "Something went wrong";
    yield put(signsecureActions.cancelDocumentFailed({ message }));
    
    if (message.includes("Unauthorized")) {
      yield put(userActions.failed({ message }));
      yield put(authActions.logout());
    }
    yield delay(1000);
    yield put(signsecureActions.cancelDocumentFailed({}));
  }
}

function* handleDeclineDocument(action: {
  type: typeof signsecureActions.declineDocumentRequest;
  payload: any;
}): SagaIterator {
  try {
    const accessToken = yield select(selectedAuthAccessToken);
    const result = yield call(
      signsecureDeclineDocument,
      action.payload,
      accessToken
    );
    yield put(signsecureActions.declineDocumentSuccess(result.data));
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    const failedMessage = message?.replace(/^HttpException:\s*/, "");
    yield put(signsecureActions.declineDocumentFailed({ message: failedMessage }));

    if (message.includes("Unauthorized")) {
      yield put(userActions.failed({ message: failedMessage }));
      yield put(authActions.logout());
    }
    yield delay(1000);
    yield put(signsecureActions.declineDocumentFailed({}));
  }
}

function* handleRejectDocument(action: {
  type: typeof signsecureActions.rejectDocumentRequest;
  payload: any;
}): SagaIterator {
  try {
    const accessToken = yield select(selectedAuthAccessToken);
    const result = yield call(
      signsecureRejectDocument,
      action.payload,
      accessToken
    );
    yield put(signsecureActions.rejectDocumentSuccess(result.data));
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    const failedMessage = message?.replace(/^HttpException:\s*/, "");
    yield put(signsecureActions.rejectDocumentFailed({ message: failedMessage }));

    if (message.includes("Unauthorized")) {
      yield put(userActions.failed({ message: failedMessage }));
      yield put(authActions.logout());
    }
    yield delay(1000);
    yield put(signsecureActions.rejectDocumentFailed({}));
  }
}

function* handleCheckingUnfinishocument(): SagaIterator {
  try {
    const documentOnFloat = yield select(selectDocumentOnFloat);

    if(documentOnFloat.documentStatus === "AFFIXING"){
      yield call(handleUnfinishAffixDocument);
    }else if(documentOnFloat.documentStatus === "APPROVE"){
      yield call(handleUnfinishApproveDocument);
    }
  } catch (error: any) {
    /* empty */
  }
}

function* handleUnfinishAffixDocument(): SagaIterator {
  try {
    const accessToken = yield select(selectedAuthAccessToken);
    const documentOnFloat = yield select(selectDocumentOnFloat);
    const result = yield call(
      signsecureViewDocument,
      documentOnFloat.id,
      accessToken
    );
    const isCompleted = yield call(checkPartiesSigned, result.data);
    if(isCompleted){
      const uri = yield call(
        signsecureDownloadDocument,
        result.data.id,
        accessToken
      );

      let folderName = "";
      if(result.data.parent){
        const parent = yield call(
          directoryDetails,
          result.data.parent,
          accessToken
        );

        folderName = parent.data.name;
      }
      
      const base64_complete = yield call(
        completeSignatureWithCreateManifest,
        uri,
        {...result.data, folderName}
      );
      const newblob = new Blob([base64_complete], { type: "application/pdf" });
      const formDataComplete = new FormData();
      formDataComplete.append("file", newblob, `${result.data.name}.pdf`);
      yield call(
        signsecureCompleteDocument,
        result.data.id,
        formDataComplete,
        accessToken
      );
      
      yield put(authActions.documentOnFloat({}));
      return;
    }

    yield put(authActions.documentOnFloat({}));
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    const failedMessage = message?.replace(/^HttpException:\s*/, "");
    
    if (message.includes("Unauthorized")) {
      yield put(userActions.failed({ message: failedMessage }));
      yield put(authActions.logout());
    }
  }
}

function* handleUnfinishApproveDocument(): SagaIterator {
  try {
    const accessToken = yield select(selectedAuthAccessToken);
    const documentOnFloat = yield select(selectDocumentOnFloat);

    const result = yield call(
      signsecureViewDocument,
      documentOnFloat.id,
      accessToken
    );

    const isCompleted = yield call(checkPartiesSigned, result.data);
    if(isCompleted){
      const uri = yield call(
        signsecureDownloadDocument,
        result.data.id,
        accessToken
      );
      let folderName = "";
      if(result.data.parent){
        const parent = yield call(
          directoryDetails,
          result.data.parent,
          accessToken
        );

        folderName = parent.data.name;
      }
      
      const base64_complete = yield call(
        completeSignatureWithCreateManifest,
        uri,
        {...result.data, folderName}
      );
      const newblob = new Blob([base64_complete], { type: "application/pdf" });
      const formDataComplete = new FormData();
      formDataComplete.append("file", newblob, `${result.data.name}.pdf`);
      yield call(
        signsecureCompleteDocument,
        result.data.id,
        formDataComplete,
        accessToken
      );
      
      yield put(authActions.documentOnFloat({}));
      return;
    }

    yield put(authActions.documentOnFloat({}));
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";

    if (message.includes("Unauthorized")) {
      yield put(userActions.failed({ message }));
      yield put(authActions.logout());
    }
  }
}

function* handlePDFEncryption(response : any, blob: any, accessToken : any ): SagaIterator { 
  try {
  //const formdata = yield call(EncryptPDFDocumentDataParser, signsecureInput);
  console.log("handlePDFEncryption response--",response);
  const formDataEncrypt = new FormData();
  formDataEncrypt.append("file", blob, `${response.data.name}.pdf`);
  formDataEncrypt.append("password",  response.data.password);
  const result = yield call(signsecureEncryptPDF, response.data.id, formDataEncrypt, accessToken);

  console.log("handlePDFEncryption result--",result);

  } catch(error: any) {
    const message = error.message || error.error || "Something went wrong";
  }
}

// Watcher Saga
function* signsecureWatcherSaga(): SagaIterator {
  yield takeEvery(userActions.fetchUserDetails.type, handleCheckingUnfinishocument);
  yield takeEvery(signsecureActions.createDocumentRequest.type, handleCreateDocumentRequest);
  yield takeEvery(signsecureActions.saveDocumentRequest.type, handleSaveDocumentRequest);
  yield takeEvery(signsecureActions.importDocumentRequest.type, handleImportDocumentRequest);
  yield takeEvery(signsecureActions.affixSignatureRequest.type, handleAffixSignature);
  yield takeEvery(signsecureActions.cancelDocumentRequest.type, handleCancelDocument);
  yield takeEvery(signsecureActions.declineDocumentRequest.type, handleDeclineDocument);
  yield takeEvery(signsecureActions.rejectDocumentRequest.type, handleRejectDocument);
  yield takeEvery(signsecureActions.viewDocumentRequest.type, handleViewDocument);
  yield takeEvery(signsecureActions.viewDocumentRequest.type, handleUserQuickSigning);
  yield takeEvery(signsecureActions.approveDocumentRequest.type, handleApproveDocument);
}

export default signsecureWatcherSaga;
