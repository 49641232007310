import { IconProps } from "@src/components/icon/interface";
import React from "react";
import colors from "../colors/index";
import SignSecure from "./SignSecure-light.svg";
import UnawaProductBlack from "./UnawaProductBlack.svg";
import BackwardNavigationDisabled from "./backward-navigation-disabled.svg";
import BackwardNavigation from "./backward-navigation.svg";
import ArrowLeft from "./create-document/arrow-left.svg";
import ArrowRight from "./create-document/arrow-right.svg";
import EmailSent from "./email_sent.svg";
import FaceSad from "./face-sad.svg";
import FaceSmileV2 from "./face-smile-v2.svg";
import FaceSmile from "./face-smile.svg";
import ForwardNavigationDisabled from "./forward-navigation-disabled.svg";
import ForwardNavigation from "./forward-navigation.svg";
import Change from "./ic_change.svg";
import DeleteSuccess from "./ic_delete_success.svg";
import Edit from "./ic_edit.svg";
import Edit2 from "./ic_edit2.svg";
import Folder from "./ic_folder.svg";
import MasterCard from "./ic_mastercard.svg";
import Maya from "./ic_maya.svg";
import Reminder from "./ic_reminder.svg";
import Remove from "./ic_remove.svg";
import Delete from "./ic_trash.svg";
import Visa from "./ic_visa.svg";
import Back from "./icon_back.svg";
import Check from "./icons8-check.svg";
import UnawaProduct from "./product_unawa.svg";
import SignHere from "./sign-here.svg";
import SignsecureLogoDrawer from "./signsecure-drawer-logo.svg";
import SignsecureLogoBlue from "./signsecure-logo-blue.svg";
import SignSecureNoV2 from "./signsecure.svg";
import SignSecureLogo from "./signsecure_logo.svg";
import SignsecureMobileLogo from "./signsecure_logo_mobile.svg";
import SignsecureAdminLogoLogin from "./signsecure-adminLogo_login.svg"

const SVGIcon = (props: IconProps) => {
  const { name, width, height } = props;
  switch (name) {
  
  case "edit":
    return <Edit width={width || 15} height={height || 24} {...props} />;
  case "edit2":
    return <Edit2 width={width || 15} height={height || 24} {...props} />;
  case "back":
    return <Back width={width || 15} height={height || 24} {...props} />;
  case "SignHere":
    return <SignHere width={width || 147} height={height || 38} {...props} />;
  case "UnawaProduct":
    return <UnawaProduct width={width || 200} height={height || 20} {...props} />;
  case "UnawaProductBlack":
    return <UnawaProductBlack width={width || 160} height={height || 20} {...props} />;
  case "reminder":
    return <Reminder width={width || 24} height={height || 24} fill={props.fill || colors.transparent}/>;
  case "SignSecure":
    return <SignSecure width={width || 270} height={height || 56} fill={props.fill || "white"} />;
  case "SignsecureLogoDrawer":
    return <SignsecureLogoDrawer width={width || 270} height={height || 56} fill={props.fill || "white"} />;
  case "signsecure-no-v2":
    return <SignSecureNoV2 width={width || 270} height={height || 56}/>;
  case "sigsecure-login-admin":
    return <SignsecureAdminLogoLogin width={width || 270} height={height || 56}/>;
  case "SignSecureLogo":
    return <SignSecureLogo width={width || 42} height={height || 49} fill={props.fill || "white"} />;
  case "SignsecureMobileLogo":
    return <SignsecureMobileLogo width={width || 180} height={height || 49} fill={props.fill || "white"} />;
  case "signsecure-logo-blue":
    return <SignsecureLogoBlue width={width || 42} height={height || 49} />;
  case "Delete":
    return <Delete width={width || 11} height={height || 13} fill={props.fill || "black"} />;
  case "delete-success":
    return <DeleteSuccess width={width || 11} height={height || 13} fill={props.fill || "black"} />;
  case "Folder":
    return <Folder width={width || 127} height={height || 99} />;
  case "maya":
    return <Maya width={width || 127} height={height || 99} />;
  case "arrow-left":
    return <ArrowLeft width={width || 8} height={height || 12} fill={props.fill || colors.border6}/>;
  case "arrow-right":
    return <ArrowRight width={width || 8} height={height || 12} fill={props.fill || colors.border6}/>;
  case "remove":
    return <Remove width={width || 73} height={height || 86} fill={props.fill || colors.colorPrimary}/>;
  case "visa":
    return <Visa width={width || 73} height={height || 86} />;
  case "master-card":
    return <MasterCard width={width || 73} height={height || 86} />;
  case "forward-navigation":
    return <ForwardNavigation width={width || 50} height={height || 43} />;
  case "backward-navigation":
    return <BackwardNavigation width={width || 50} height={height || 43} />;
  case "Check":
    return <Check width={width || 73} height={height || 86} fill={props.fill || colors.colorPrimary}/>;
  case "change":
    return <Change width={width || 15} height={height || 24} {...props} />;
  case "email-sent":
    return <EmailSent width={width || 73} height={height || 75} {...props} />;
  case "face-smile":
    return <FaceSmile width={width || 80} height={height || 80} />;
  case "face-smile-v2":
    return <FaceSmileV2 width={width || 80} height={height || 80} />;
  case "face-sad":
    return <FaceSad width={width || 80} height={height || 80} {...props} />;
  case "forward-navigation-disabled":
    return <ForwardNavigationDisabled width={width || 50} height={height || 43} />;
  case "backward-navigation-disabled":
    return <BackwardNavigationDisabled width={width || 50} height={height || 43} />;
  default:
    return null;
  }
};

export default SVGIcon;
