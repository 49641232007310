/* eslint-disable no-case-declarations */
import _ from "lodash";
import moment from "moment";
import { degrees } from "pdf-lib";
import { add, multiply, substract } from "./computation-helper";
import { characterTrim } from "./validation-helper";

const descendingComparator = (a: any, b: any, orderBy: string) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order: any, orderBy: string) => {
  return order === "descending"
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
};

// ARRAY FILTER BY SEARCH AND ORDERBY
export const applySortFilter = (array: any, comparator: any, query: string = "", properties: string = "", properties2: string = "") => {
  const stabilizedThis = array.map((el: any, index: number) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  if (query) {
    return _.filter(array, (_user) => 
      `${_user[properties] || ""}`.toLowerCase().indexOf(query.toLowerCase()) !== -1 || 
      `${_user[properties2] || ""}`.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }

  return stabilizedThis.map((el: any) => el[0]);
};

// CONVERT STEPS STRING VALUE TO INDEXING
export const getActiveIndex = (screen: string) => {
  switch(screen){
  case "UPLOAD":
    return 0;
  case "DETAILS":
    return 1;
  case "PARTIES":
    return 2;
  case "FIELDS":
    return 3;
  case "REVIEW":
    return 4;
  case "SENT":
    return 5;
  case "DOWNLOAD":
    return 6;
  default:
    return 0;
  }
};

// CREATE CONTINOUS STEPS BY PRESSING NEXT BUTTON
export const getNextSteps = (screen: string) => {
  switch(screen){
  case "UPLOAD":
    return "DETAILS";
  case "DETAILS":
    return "PARTIES";
  case "PARTIES":
    return "FIELDS";
  case "FIELDS":
    return "REVIEW";
  case "REVIEW":
    return "SENT";
  default:
    return "DETAILS";
  }
};

// GET AUTHOR FROM 'workflow.auditTrail' array
export const getAuthorFromAuditTrail = (document: any = {}) => {
  const auditTrail = document?.workflow?.auditTrail ?? [];

  return auditTrail.find((item: any) => item.details === "Created") || {};
};

// COMPARE AUTHOR VS CURRENT USER TO SIGN
export const compareAuthorVsSigner = (document: any = {}, userParty: any) => {
  const auditTrail = document?.workflow?.auditTrail ?? [];
  const author = auditTrail.find((item: any) => item.details === "Created") || {};
  const authorEmail = author?.author?.email ?? "";

  return authorEmail === userParty.email;
};

// GET COMPLETED FROM 'workflow.auditTrail' array
export const getCompletedFromAuditTrail = (document: any = {}) => {
  const auditTrail = document?.workflow?.auditTrail ?? [];

  return auditTrail.find((item: any) => item.details === "Completed") || {};
};

// CHECK EMAIL HAS IN THE LIST OF PARTiES
export const checkEmailHasInListed = (document: any = {}, email: string) => {
  const parties = document?.workflow?.parties ?? [];
  const result = parties.find((item: any) => item.email === email);

  return !_.isEmpty(result);
};

// CHECK IF ALL PARTIES ARE SIGNED EXCEPT CURRENT SIGNIE
export const checkPartiesSigned = (document: any = {}) => {
  const parties = document?.workflow?.parties ?? [];
  const result = parties.filter((item: any) => item.executed || item.role === "COPY");

  return result.length === parties.length;
};

// FILTER DOCUMENTS BASE ON COMPLETED, FOR MY ACTIONS AND FOR OTHER'S ACTION
export const getDocumentsFilterByHeader = (documents: any[], email: string, actionId: string) => {
  switch(actionId){
  case "allDocuments":
    return documents;
  case "forMyActions":
    const forMyActions = _.reduce(documents, (result: any[], value: any) => {
      if(value.type === "file"){
        const parties = value?.workflow?.parties ?? [];
        const party = parties.find((p: any) => p.email === email);
        if((["INPROGRESS", "PENDING"].includes(value.status) && !_.isEmpty(party)) && (!party.executed && party.role !== "COPY")){
          result.push(value);
        }
      } else if(value.type === "folder") {
        const children: any = [];
        (value?.children ?? []).forEach((item: any) => {
          const parties = item?.workflow?.parties ?? [];
          const party = parties.find((p: any) => p.email === email);
          if((["INPROGRESS", "PENDING"].includes(item.workflow.status) && !_.isEmpty(party)) && (!party.executed && party.role !== "COPY")){
            children.push(item);
          }
        });

        if(!_.isEmpty(children)){
          result.push({...value, children});
        }
      }
  
      return result;
    },[]);
    return forMyActions;
  case "forOtherActions":
    const forOtherActions = _.reduce(documents, (result: any[], value: any) => {
      if(value.type === "file"){
        const parties = value?.workflow?.parties ?? [];
        const party = parties.find((p: any) => p.email !== email);
        if(["INPROGRESS", "PENDING"].includes(value.status) && !_.isEmpty(party) && !party.executed){
          result.push(value);
        }
      } else if(value.type === "folder") {
        const children: any = [];
        (value?.children ?? []).forEach((item: any) => {
          const parties = item?.workflow?.parties ?? [];
          const party = parties.find((p: any) => p.email !== email);
          if(["INPROGRESS", "PENDING"].includes(item.workflow.status) && !_.isEmpty(party) && !party.executed){
            children.push(item);
          }
        });
        if(!_.isEmpty(children)){
          result.push({...value, children});
        }
      }
  
      return result;
    },[]);
    return forOtherActions;
  case "completed":
    const completed = _.reduce(documents, (result: any[], value: any) => {
      if(value.type === "file"){
        if(["COMPLETED"].includes(value.status)){
          result.push(value);
        }
      } else if(value.type === "folder") {
        const children: any = [];
        (value?.children ?? []).forEach((item: any) => {
          if(["COMPLETED"].includes(item.workflow.status)){
            children.push(item);
          }
        });
        if(!_.isEmpty(children)){
          result.push({...value, children});
        }
      }
  
      return result;
    },[]);
    return completed;
  }
};

// COUNT DOCUMENTS BASE ON COMPLETED, FOR MY ACTIONS AND FOR OTHER'S ACTION
export const getCountedDocuments = (documents: any[], email: string) => {
  const completed = _.reduce(documents, (result: any[], value: any) => {
    if(value.type === "file"){
      if(["COMPLETED"].includes(value.status)){
        result.push(value);
      }
    } else if(value.type === "folder") {
      const children: any = [];
      (value?.children ?? []).forEach((item: any) => {
        if(["COMPLETED"].includes(item.workflow.status)){
          children.push(item);
        }
      });

      if(!_.isEmpty(children)){
        result.push({...value, children});
      }
    }

    return result;
  },[]);

  const forMyActions = _.reduce(documents, (result: any[], value: any) => {
    if(value.type === "file"){
      const parties = value?.workflow?.parties ?? [];
      const party = parties.find((p: any) => p.email === email);
      if((["INPROGRESS", "PENDING"].includes(value.status) && !_.isEmpty(party)) && (!party.executed && party.role !== "COPY")){
        result.push(value);
      }
    } else if(value.type === "folder") {
      const children: any = [];
      (value?.children ?? []).forEach((item: any) => {
        const parties = item?.workflow?.parties ?? [];
        const party = parties.find((p: any) => p.email === email);
        if(["INPROGRESS", "PENDING"].includes(item.workflow.status) && !_.isEmpty(party) && !party.executed && party.role !== "COPY"){
          children.push(item);
        }
      });

      if(!_.isEmpty(children)){
        result.push({...value, children});
      }
    }

    return result;
  },[]);

  const forOtherActions = _.reduce(documents, (result: any[], value: any) => {
    if(value.type === "file"){
      const parties = value?.workflow?.parties ?? [];
      const party = parties.find((p: any) => p.email !== email);
      if(["INPROGRESS", "PENDING"].includes(value.status) && !_.isEmpty(party) && !party.executed){
        result.push(value);
      }
    } else if(value.type === "folder") {
      const children: any = [];
      (value?.children ?? []).forEach((item: any) => {
        const parties = item?.workflow?.parties ?? [];
        const party = parties.find((p: any) => p.email !== email);
        if(["INPROGRESS", "PENDING"].includes(item.workflow.status) && !_.isEmpty(party) && !party.executed){
          children.push(item);
        }
      });

      if(!_.isEmpty(children)){
        result.push({...value, children});
      }
    }

    return result;
  },[]);

  return {
    allDocuments: documents.length,
    forMyActions: forMyActions.length,
    forOtherActions: forOtherActions.length,
    completed: completed.length,
  };
};

// FILTER PARTIES ARRAY BY ROLE = SIGN AND SORTED BY EXECUTED
export const getPartiesHasExecute = (document: any = {}) => {
  const parties = document?.workflow?.parties ?? [];
  const result = _.chain(parties).filter((item: any) => item.executed && item.role === "SIGN")
    .orderBy(["executed"], ["asc"]).value();

  return result;
};

// FILTER PARTIES ARRAY BY ROLE = APPROVE AND SORTED BY EXECUTED
export const getPartiesApprove = (document: any = {}) => {
  const parties = document?.workflow?.parties ?? [];
  const result = _.chain(parties).filter((item: any) => item.executed && item.role === "APPROVE")
    .orderBy(["executed"], ["asc"]).value();

  return result;
};

// CHECK ALL PARTIES WITH ROLE SIGN ARE HAVE PUT SIGNATURE ON FIELDS
export const areAllSignPartiesIncluded = (signatures: any[], parties: any[]) => {
  const requireTypes = ["signature","signature-date","signature-name","signature-name-designation"];
  const signatureEmails = signatures.reduce((result: any[], value: any) => {
    value.layerFields.forEach((item: any) => {
      if(requireTypes.includes(item.type)){
        result.push(item.email);
      }
    });
    return result;
  },[]);

  parties = parties.filter((item) => item.role === "Needs to sign");

  // Check if all party emails are included in the signature emails
  return parties.every(party => signatureEmails.includes(party.email));
};

// AFFIX SIGN LOGO AND DATE POSITION BASE ON ROTATION
export const signLogoAndDatePosition = (config: any, heigh1_3rd: number, heigh1_7th: number, dateFontSize: number, pageIndex: any) => {
  const { width, height } = pageIndex.getSize();
  const { angle } = pageIndex.getRotation();

  switch(angle){
  case 90:
    switch (Math.trunc(config.rotation || 0)) {
    case 90:
      const xx = substract(config.x, config.height) + heigh1_7th;
      const yy = config.y;
      return {
        x: yy,
        y: xx,
        logoX: yy + config.width * 0.054,
        logoY: xx + heigh1_3rd * 0.2,
        textX: yy + config.width * 0.43,
        textY: xx + heigh1_3rd / 2 - dateFontSize / 2,
        rotate: degrees(0),
      };
    case -90:
      const xxx = add(config.x, config.height) - heigh1_7th;
      const yyy = config.y;
      return {
        x: yyy,
        y: xxx,
        logoX: yyy - config.width * 0.054,
        logoY: xxx - heigh1_3rd * 0.2,
        textX: yyy - config.width * 0.43,
        textY: xxx - heigh1_3rd / 2 + dateFontSize / 2,
        rotate: degrees(180),
      };
    case 180:
      const yyyy = substract(config.y, heigh1_3rd);      
      return {
        x: yyyy,
        y: config.x,
        logoX: add(yyyy, heigh1_3rd * 0.2),
        logoY: substract(config.x, config.width * 0.054),
        textX: (yyyy + heigh1_3rd / 2) - dateFontSize / 2,
        textY: substract(config.x, config.width * 0.43),
        rotate: degrees(270),
      };
    default:
      const y = add(config.y, heigh1_3rd);      
      return {
        x: y,
        y: config.x,
        logoX: y - heigh1_3rd * 0.2,
        logoY: config.x + config.width * 0.054,
        textX: (y - heigh1_3rd / 2) + dateFontSize / 2,
        textY: config.x + config.width * 0.43,
        rotate: degrees(angle),
      };
    }
  case 270:
    switch (Math.trunc(config.rotation || 0)) {
    case 90:
      const xx = substract(config.x, config.height) + heigh1_7th;
      const yy = config.y;
      return {
        x: substract(width, yy),
        y: substract(height, xx),
        logoX: substract(width, yy + config.width * 0.054),
        logoY: substract(height, xx + heigh1_3rd * 0.2),
        textX: substract(width, yy + config.width * 0.43),
        textY: substract(height, xx + heigh1_3rd / 2 - dateFontSize / 2),
        rotate: degrees(180),
      };
    case -90:
      const xxx = add(config.x, config.height) - heigh1_7th;
      const yyy = config.y;
      return {
        x: substract(width, yyy),
        y: substract(height, xxx),
        logoX: substract(width, yyy - config.width * 0.054),
        logoY: substract(height, xxx - heigh1_3rd * 0.2),
        textX: substract(width, yyy - config.width * 0.43),
        textY: substract(height, xxx - heigh1_3rd / 2 + dateFontSize / 2),
        rotate: degrees(0),
      };
    case 180:
      const yyyy = substract(config.y, heigh1_3rd);      
      return {
        x: substract(width, yyyy),
        y: substract(height, config.x),
        logoX: substract(width, add(yyyy, heigh1_3rd * 0.2)),
        logoY: substract(height, substract(config.x, config.width * 0.054)),
        textX: substract(width, substract((yyyy + heigh1_3rd / 2), dateFontSize / 2)),
        textY: substract(height, substract(config.x, config.width * 0.43)),
        rotate: degrees(90),
      };
    default:
      const y = add(config.y, heigh1_3rd);      
      return {
        x: substract(width, y),
        y: substract(height, config.x),
        logoX: substract(width, y - heigh1_3rd * 0.2),
        logoY: substract(height, config.x + config.width * 0.054),
        textX: substract(width, (y - heigh1_3rd / 2) + dateFontSize / 2),
        textY: substract(height, config.x + config.width * 0.43),
        rotate: degrees(angle),
      };
    }
  default:
    switch (Math.trunc(config.rotation || 0)) {
    case 90:
      const xx = substract(config.x, config.height) + heigh1_7th;
      const yy = substract(height, config.y);
        
      return {
        x: xx,
        y: yy,
        logoX: xx + heigh1_3rd * 0.2,
        logoY: yy - config.width * 0.054,
        textX: xx + heigh1_3rd / 2 - dateFontSize / 2,
        textY: yy - config.width * 0.43,
        rotate: degrees(-(config.rotation || 0)),
      };
    case -90:
      const xxx = add(config.x, config.height) - heigh1_7th;
      const yyy = substract(height, config.y);
      return {
        x: xxx,
        y: yyy,
        logoX: xxx - heigh1_3rd * 0.2,
        logoY: yyy + config.width * 0.054,
        textX: xxx - heigh1_3rd / 2 + dateFontSize / 2,
        textY: yyy + config.width * 0.43,
        rotate: degrees(-(config.rotation || 0)),
      };
    default:
      const y = substract(height, add(config.y, config.height));
      return {
        x: config.x,
        y: y + heigh1_7th,
        logoX: config.x + config.width * 0.054,
        logoY: y + heigh1_7th + heigh1_3rd * 0.2,
        textX: config.x + config.width * 0.43,
        textY: y + heigh1_7th + heigh1_3rd / 2 - dateFontSize / 2,
        rotate: degrees(-(config.rotation || 0)),
      };
    }
  }
};

// AFFIX SIGNATURE POSITION BASE ON ROTATION
export const signaturePosition = (image: any, config: any, pageIndex: any) => {
  const scaled = image.scaleToFit(config.width, config.height * 0.67);
  const { width, height } = pageIndex.getSize();
  const { angle } = pageIndex.getRotation();

  switch(angle){
  case 90:
    switch (Math.trunc(config.rotation || 0)) {
    case 90:
      return {
        y: substract(config.x, config.height),
        x: config.y + ((config.width - scaled.width) / 2),
        width: scaled.width,
        height: scaled.height,
        rotate: degrees(0),
      };
    case -90:
      return {
        y: add(config.x, config.height),
        x: config.y - ((config.width - scaled.width) / 2),
        width: scaled.width,
        height: scaled.height,
        rotate: degrees(180),
      };
    case 180:
      return {
        y: substract(config.x, ((config.width - scaled.width) / 2)),
        x: substract(config.y, config.height),
        width: scaled.width,
        height: scaled.height,
        rotate: degrees(270),
      };
    default:
      return {
        y: config.x + ((config.width - scaled.width) / 2),
        x: add(config.y, config.height),
        width: scaled.width,
        height: scaled.height,
        rotate: degrees(angle),
      };
    
    }
  case 270:
    switch (Math.trunc(config.rotation || 0)) {
    case 90:
      return {
        y: substract(height, substract(config.x, config.height)),
        x: substract(width, config.y + ((config.width - scaled.width) / 2)),
        width: scaled.width,
        height: scaled.height,
        rotate: degrees(180),
      };
    case -90:
      return {
        y: substract(height, add(config.x, config.height)),
        x: substract(width, config.y - ((config.width - scaled.width) / 2)),
        width: scaled.width,
        height: scaled.height,
        rotate: degrees(0),
      };
    case 180:
      return {
        y: substract(height, substract(config.x, ((config.width - scaled.width) / 2))),
        x: substract(width, substract(config.y, config.height)),
        width: scaled.width,
        height: scaled.height,
        rotate: degrees(90),
      };
    default:
      return {
        y: substract(height, config.x + ((config.width - scaled.width) / 2)),
        x: substract(width, add(config.y, config.height)),
        width: scaled.width,
        height: scaled.height,
        rotate: degrees(angle),
      };
    }
  default:
    switch (Math.trunc(config.rotation || 0)) {
    case 90:
      return {
        x: substract(config.x, config.height),
        y: substract(height, config.y + ((config.width - scaled.width) / 2)),
        width: scaled.width,
        height: scaled.height,
        rotate: degrees(-(config.rotation || 0)),
      };
    case -90:
      return {
        x: add(config.x, config.height),
        y: substract(height, config.y - ((config.width - scaled.width) / 2)),
        width: scaled.width,
        height: scaled.height,
        rotate: degrees(-(config.rotation || 0)),
      };
    default:
      return {
        x: config.x + ((config.width - scaled.width) / 2),
        y: substract(height, add(config.y, config.height)),
        width: scaled.width,
        height: scaled.height,
        rotate: degrees(-(config.rotation || 0)),
      };
    }
  }
};

// AFFIX TEXT POSITION BASE ON ROTATION (SIGNATURE-NAME, SIGNATURE-DATE, SIGNATURE-NAME-DESIGNATION)
export const signTextPosition = (config: any, textConfig: any, pageIndex: any) => {
  const { width, height } = pageIndex.getSize();
  const { angle } = pageIndex.getRotation();

  switch(angle){
  case 90:
    switch (Math.trunc(config.rotation || 0)) {
    case 90:
      return {
        y: substract(config.x, textConfig.y + multiply(textConfig.fontSize, 0.81)),
        x: config.y,
        rotate: degrees(0),
      };
    case -90:
      return {
        y: add(config.x, textConfig.y + multiply(textConfig.fontSize, 0.81)),
        x: config.y,
        rotate: degrees(180),
      };
    case 180:
      return {
        y: config.x,
        x: substract(config.y, textConfig.y + multiply(textConfig.fontSize, 0.81)),
        rotate: degrees(270),
      };
    default:
      return {
        y: config.x,
        x: add(config.y, textConfig.y + multiply(textConfig.fontSize, 0.81)),
        rotate: degrees(angle),
      };
    }
  case 270:
    switch (Math.trunc(config.rotation || 0)) {
    case 90:
      return {
        y: substract(height, substract(config.x, textConfig.y + multiply(textConfig.fontSize, 0.81))),
        x: substract(width, config.y),
        rotate: degrees(180),
      };
    case -90:
      return {
        y: substract(height, add(config.x, textConfig.y + multiply(textConfig.fontSize, 0.81))),
        x: substract(width, config.y),
        rotate: degrees(0),
      };
    case 180:
      return {
        y: substract(height, config.x),
        x: substract(width, substract(config.y, textConfig.y + multiply(textConfig.fontSize, 0.81))),
        rotate: degrees(90),
      };
    default:
      return {
        y: substract(height, config.x),
        x: substract(width, add(config.y, textConfig.y + multiply(textConfig.fontSize, 0.81))),
        rotate: degrees(angle),
      };
    }
  default:
    switch (Math.trunc(config.rotation || 0)) {
    case 90:
      return {
        x: substract(config.x, textConfig.y + multiply(textConfig.fontSize, 0.81)),
        y: substract(height, config.y),
      };
    case -90:
      return {
        x: add(config.x, textConfig.y + multiply(textConfig.fontSize, 0.81)),
        y: substract(height, config.y),
      };
    default:
      return {
        x: config.x,
        y: substract(height, add(config.y, textConfig.y + multiply(textConfig.fontSize, 0.81))),
      };
    }
  }
};

// AFFIX TEXTBOX POSITION BASE ON ROTATION
export const signTextBoxPosition = (config: any, textConfig: any, pageIndex: any) => {
  const { width, height } = pageIndex.getSize();
  const { angle } = pageIndex.getRotation();

  switch(angle){
  case 90:
    switch (Math.trunc(config.rotation || 0)) {
    case 90:
      return {
        y: substract(config.x, multiply(textConfig.fontSize, 1.35)),
        x: config.y + 2,
        rotate: degrees(0),
      };
    case -90:
      return {
        y: add(config.x, multiply(textConfig.fontSize, 1.35)),
        x: config.y,
        rotate: degrees(180),
      };
    case 180:
      return {
        y: config.x + 2,
        x: config.y - multiply(textConfig.fontSize, 1.35),
        rotate: degrees(270),
      };
    default:
      return {
        y: config.x + 2,
        x: config.y + multiply(textConfig.fontSize, 1.35),
        rotate: degrees(angle),
      };
    }
  case 270:
    switch (Math.trunc(config.rotation || 0)) {
    case 90:
      return {
        y: substract(height, substract(config.x, multiply(textConfig.fontSize, 1.35))),
        x: substract(width, config.y + 2),
        rotate: degrees(180),
      };
    case -90:
      return {
        y: substract(height, add(config.x, multiply(textConfig.fontSize, 1.35))),
        x: substract(width, config.y),
        rotate: degrees(0),
      };
    case 180:
      return {
        y: substract(height, config.x + 2),
        x: substract(width, config.y - multiply(textConfig.fontSize, 1.35)),
        rotate: degrees(90),
      };
    default:
      return {
        y: substract(height, config.x + 2),
        x: substract(width, config.y + multiply(textConfig.fontSize, 1.35)),
        rotate: degrees(angle),
      };
    }
  default:
    switch (Math.trunc(config.rotation || 0)) {
    case 90:
      return {
        x: substract(config.x, multiply(textConfig.fontSize, 1.35)),
        y: substract(height, config.y + 2),
        rotate: degrees(-(config.rotation || 0)),
      };
    case -90:
      return {
        x: add(config.x, multiply(textConfig.fontSize, 1.35)),
        y: substract(height, config.y),
        rotate: degrees(-(config.rotation || 0)),
      };
    default:
      return {
        x: config.x + 2,
        y: substract(height, config.y + multiply(textConfig.fontSize, 1.35)),
        rotate: degrees(-(config.rotation || 0)),
      };
    }
  }
};

// AFFIX CHECKBOX POSITION BASE ON ROTATION
export const signCheckBoxPosition = (config: any, inputConfig: any, checkConfig: any, pageIndex: any, scaled: any) => {
  const { width, height } = pageIndex.getSize();
  const { angle } = pageIndex.getRotation();

  switch(angle){
  case 90:
    switch (Math.trunc(config.rotation || 0)) {
    case 90:
      return {
        checkY: config.x - checkConfig.width,
        checkX: checkConfig.y,
        textY: substract(config.x, multiply(inputConfig.fontSize, 1.35)),
        textX: config.y + 2 + checkConfig.width + checkConfig.marginRight,
        cy: substract(config.x, checkConfig.width) + ((checkConfig.width - scaled.width) / 2),
        cx: (checkConfig.y + (checkConfig.height - scaled.height) / 2),
        rotate: degrees(0),
      };
    case -90:
      return {
        checkY: config.x + checkConfig.width,
        checkX: checkConfig.y,
        textY: add(config.x, multiply(inputConfig.fontSize, 1.35)),
        textX: config.y - (checkConfig.width + checkConfig.marginRight + 2),
        cy: config.x + checkConfig.width - ((checkConfig.width - scaled.width) / 2),
        cx: checkConfig.y - ((checkConfig.height - scaled.height) / 2),
        rotate: degrees(180),
      };
    case 180:
      return {
        checkY: checkConfig.x,
        checkX: checkConfig.y - checkConfig.height,
        textY: substract((config.x - 2), checkConfig.width + checkConfig.marginRight),
        textX: config.y - multiply(inputConfig.fontSize, 1.35),
        cy: checkConfig.x - ((checkConfig.width - scaled.width) / 2),
        cx: add(checkConfig.y - checkConfig.height, ((checkConfig.height - scaled.height) / 2)),
        rotate: degrees(270),
      };
    default:
      return {
        checkY: checkConfig.x,
        checkX: checkConfig.y + checkConfig.height,
        textY: config.x + 2 + checkConfig.width + checkConfig.marginRight,
        textX: config.y + multiply(inputConfig.fontSize, 1.35),
        cy: checkConfig.x + ((checkConfig.width - scaled.width) / 2),
        cx: checkConfig.y + checkConfig.height - ((checkConfig.height - scaled.height) / 2),
        rotate: degrees(angle),
      };
    }
  case 270:
    switch (Math.trunc(config.rotation || 0)) {
    case 90:
      return {
        checkY: substract(height, config.x - checkConfig.width),
        checkX: substract(width, checkConfig.y),
        textY: substract(height, substract(config.x, multiply(inputConfig.fontSize, 1.35))),
        textX: substract(width, config.y + 2 + checkConfig.width + checkConfig.marginRight),
        cy: substract(height, substract(config.x, checkConfig.width) + ((checkConfig.width - scaled.width) / 2)),
        cx: substract(width, (checkConfig.y + (checkConfig.height - scaled.height) / 2)),
        rotate: degrees(180),
      };
    case -90:
      return {
        checkY: substract(height, config.x + checkConfig.width),
        checkX: substract(width, checkConfig.y),
        textY: substract(height, add(config.x, multiply(inputConfig.fontSize, 1.35))),
        textX: substract(width, config.y - (checkConfig.width + checkConfig.marginRight + 2)),
        cy: substract(height, config.x + checkConfig.width - ((checkConfig.width - scaled.width) / 2)),
        cx: substract(width, checkConfig.y) + ((checkConfig.height - scaled.height) / 2),
        rotate: degrees(0),
      };
    case 180:
      return {
        checkY: substract(height, checkConfig.x),
        checkX: substract(width, checkConfig.y - checkConfig.height),
        textY: substract(height, substract((config.x - 2), checkConfig.width + checkConfig.marginRight)),
        textX: substract(width, config.y - multiply(inputConfig.fontSize, 1.35)),
        cy: substract(height, checkConfig.x - ((checkConfig.width - scaled.width) / 2)),
        cx: substract(width, checkConfig.y - checkConfig.height) - ((checkConfig.height - scaled.height) / 2),
        rotate: degrees(90),
      };
    default:
      return {
        checkY: substract(height, checkConfig.x),
        checkX: substract(width, checkConfig.y + checkConfig.height),
        textY: substract(height, config.x + 2 + checkConfig.width + checkConfig.marginRight),
        textX: substract(width, config.y + multiply(inputConfig.fontSize, 1.35)),
        cy: substract(height, checkConfig.x + ((checkConfig.width - scaled.width) / 2)),
        cx: substract(width, checkConfig.y + checkConfig.height) + ((checkConfig.height - scaled.height) / 2),
        rotate: degrees(angle),
      };
    }
  default:
    switch (Math.trunc(config.rotation || 0)) {
    case 90:
      return {
        checkX: config.x - checkConfig.width,
        checkY: substract(height, checkConfig.y + checkConfig.height),
        textX: substract(config.x, multiply(inputConfig.fontSize, 1.35)),
        textY: substract(height, config.y + 2 + checkConfig.width + checkConfig.marginRight),
        cx: substract(config.x, checkConfig.width) + ((checkConfig.width - scaled.width) / 2),
        cy: substract(height, (checkConfig.y + (checkConfig.height - scaled.height) / 2)),
        rotate: degrees(-(config.rotation || 0)),
      };
    case -90:
      return {
        checkX: config.x,
        checkY: substract(height, checkConfig.y),
        textX: add(config.x, multiply(inputConfig.fontSize, 1.35)),
        textY: substract(height, config.y - (checkConfig.width + checkConfig.marginRight + 2)),
        cx: config.x + checkConfig.width + ((checkConfig.width - scaled.width) / 2),
        cy: substract(height, checkConfig.y) + ((checkConfig.height - scaled.height) / 2),
        rotate: degrees(-(config.rotation || 0)),
      };
    default:
      return {
        checkX: checkConfig.x,
        checkY: substract(height, checkConfig.y + checkConfig.height),
        textX: config.x + 2 + checkConfig.width + checkConfig.marginRight,
        textY: substract(height, config.y + multiply(inputConfig.fontSize, 1.35)),
        cx: checkConfig.x + ((checkConfig.width - scaled.width) / 2),
        cy: substract(height, checkConfig.y + checkConfig.height) + ((checkConfig.height - scaled.height) / 2),
        rotate: degrees(-(config.rotation || 0)),
      };
    }
  }
};

// AFFIX TEXT POSITION BASE ON ROTATION (FULL-NAME, DATE-TIME, DESIGNATION)
export const signSingleTextPosition = (config: any, textConfig: any, pageIndex: any) => {
  const { width, height } = pageIndex.getSize();
  const { angle } = pageIndex.getRotation();
  switch(angle){
  case 90:
    switch (Math.trunc(config.rotation || 0)) {
    case 90:
      return {
        y: substract(config.x, multiply(textConfig.fontSize, 0.81)),
        x: config.y,
        rotate: degrees(0),
      };
    case -90:
      return {
        y: add(config.x, multiply(textConfig.fontSize, 0.81)),
        x: config.y,
        rotate: degrees(180),
      };
    case 180:
      return {
        y: config.x,
        x: config.y - multiply(textConfig.fontSize, 0.81),
        rotate: degrees(270),
      };
    default:
      return {
        y: config.x,
        x: config.y + multiply(textConfig.fontSize, 0.81),
        rotate: degrees(angle),
      };
    }
  case 270:
    switch (Math.trunc(config.rotation || 0)) {
    case 90:
      return {
        y: substract(height, substract(config.x, multiply(textConfig.fontSize, 0.81))),
        x: substract(width, config.y),
        rotate: degrees(180),
      };
    case -90:
      return {
        y: substract(height, add(config.x, multiply(textConfig.fontSize, 0.81))),
        x: substract(width, config.y),
        rotate: degrees(0),
      };
    case 180:
      return {
        y: substract(height, config.x),
        x: substract(width, config.y - multiply(textConfig.fontSize, 0.81)),
        rotate: degrees(90),
      };
    default:
      return {
        y: substract(height, config.x),
        x: substract(width, config.y + multiply(textConfig.fontSize, 0.81)),
        rotate: degrees(angle),
      };
    }
  default:
    switch (Math.trunc(config.rotation || 0)) {
    case 90:
      return {
        x: substract(config.x, multiply(textConfig.fontSize, 0.81)),
        y: substract(height, config.y),
        rotate: degrees(-(config.rotation || 0)),
      };
    case -90:
      return {
        x: add(config.x, multiply(textConfig.fontSize, 0.81)),
        y: substract(height, config.y),
        rotate: degrees(-(config.rotation || 0)),
      };
    default:
      return {
        x: config.x,
        y: substract(height, config.y + multiply(textConfig.fontSize, 0.81)),
        rotate: degrees(-(config.rotation || 0)),
      };
    }
  }
};

// AFFIX QR CODE BASE ON ROTATION
export const generateQRCodePosition = (position: string, page: any ) => {
  const { width, height } = page.getSize();
  const { angle } = page.getRotation();
  const qr_size = 60;
  const qr_padding = 10;
  const qritem = {
    x: qr_padding,
    y: qr_padding,
    width: qr_size,
    height: qr_size,
    rotate: degrees(angle),
  };

  switch(angle){
  case 90:
    switch (position) {
    case "Bottom left":
      qritem.y = qr_padding;
      qritem.x = width - qr_padding;
      return qritem;
    case "Bottom right":
      qritem.x = width - (qr_padding);
      qritem.y = qr_padding;
      return qritem;
    case "Top left":
      qritem.x = (qr_size + qr_padding);
      qritem.y = (qr_padding);
      return qritem;
    case "Top right":
      qritem.x = (qr_size + qr_padding);
      qritem.y = height - (qr_size + qr_padding);
      return qritem;
    default:
      return qritem;
    }
  case 270:
    switch (position) {
    case "Bottom left":
      qritem.y = height - (qr_padding);
      qritem.x = qr_padding;
      return qritem;
    case "Bottom right":
      qritem.x = qr_padding;
      qritem.y = qr_size + qr_padding;
      return qritem;
    case "Top left":
      qritem.x = width - (qr_size + qr_padding);
      qritem.y = (qr_size + qr_padding);
      return qritem;
    case "Top right":
      qritem.x = width - (qr_size + qr_padding);
      qritem.y = height - (qr_padding);
      return qritem;
    default:
      return qritem;
    }
  default:
    switch (position) {
    case "Bottom left":
      return qritem;
    case "Bottom right":
      qritem.x = width - (qr_size + qr_padding);
      qritem.y = qr_padding;
      return qritem;
    case "Top left":
      qritem.y = height - (qr_size + qr_padding);
      return qritem;
    case "Top right":
      qritem.x = width - (qr_size + qr_padding);
      qritem.y = height - (qr_size + qr_padding);
      return qritem;
    default:
      return qritem;
    }
  }
};

// GET CONFIG BASE ON TYPES
export const getTextConfig = (field: any) => {
  switch(field.type){
  case "designation":
    return field.designationConfig;
  case "signature-date":
  case "date-time":
    return field.dateConfig;
  case "date":
    return field.dateConfig;
  case "signature-name":
  case "signature-name-designation":
  case "full-name":
    return field.nameConfig;
  case "textbox":
  case "checkbox":
    return field.inputConfig;
  }
};

// CREATE POSITION FOR THE PDF VIEW
export const getSignatoryLabels = () => {
  const labels = [
    {label1: "Name:", l1_x: 37, l1_value_x: 68, size: 10, label2: "Viewed:", l2_x: 325, l2_value_x: 363},
    {label1: "Login Type:", l1_x: 37, l1_value_x: 91, size: 10, label2: "Signed/Approved:", l2_x: 325, l2_value_x: 410},
    {label1: "Signature Type:", l1_x: 37, l1_value_x: 110, size: 10, label2: "Signature ID:", l2_x: 325, l2_value_x: 385},
    {label1: "IP Address:", l1_x: 37, l1_value_x: 90, size: 10 },
    // label2: "Region/City:", l2_x: 325, l2_value_x: 383
  ];

  return labels;
};

// FOR GENERATING MANIFEST - GET CORRESPONDING VALUE FROM LABEL
export const getSinatoryAndApproverValue = (label: string, value: any = " ") => {

  switch(label){
  case "Name:":
    return characterTrim(`${value.name} | ${value.email}`);
  case "Login Type:":
    return value.loginType;
  case "Signature Type:":
    return value.signatureType || "N/A";
  case "IP Address:":
    return value.ip || "N/A";
  case "Viewed:":
    return `${moment.unix(value.viewed).format('MMM DD, YYYY | hh:mm A ([GMT] Z)')}`;
  case "Signed/Approved:":
    return `${moment.unix(value.executed).format('MMM DD, YYYY | hh:mm A ([GMT] Z)')}`;
  case "Signature ID:":
    return value.signatureId || "N/A";
  // case "Region/City:":
  //   return value.regionCity || "N/A";
  }
};

export const getOtherActions = (status: any, user: any, isSameAuthor: boolean, enableSignature: boolean) => {
  if(["DECLINED", "REJECTED", "CANCELLED", "COMPLETED"].includes(status)){
    return ["Audit Trail", "Document details"];
  }else if(["SIGNED", "APPROVED"].includes(user.status) && isSameAuthor){
    return ["Audit Trail", "Document details", "Cancel document"];
  }else if(user.role === "SIGN" && !enableSignature){
    return ["Audit Trail", "Document details"];
  }else if(user.role === "SIGN" && isSameAuthor){
    return ["Audit Trail", "Document details", "Decline to sign", "Cancel document"];
  }else if(user.role === "SIGN" && !isSameAuthor){
    return ["Audit Trail", "Document details", "Decline to sign"];
  }else if(user.role === "APPROVE" && !enableSignature){
    return ["Audit Trail", "Document details"];
  }else if(user.role === "APPROVE" && isSameAuthor){
    return ["Audit Trail", "Document details", "Reject document", "Cancel document"];
  }else if(user.role === "APPROVE" && !isSameAuthor){
    return ["Audit Trail", "Document details", "Reject document"];
  }else if(user.role === "COPY"){
    return ["Audit Trail", "Document details", "Cancel document"];
  }else{
    return ["Audit Trail", "Document details", "Cancel document"];
  }
};

export const getRoleLabel = (role: string) => {
  switch(role){
  case "SIGN":
    return "Needs to sign";
  case "APPROVE":
    return "Needs to approve";
  case "COPY":
    return "Receives a copy";
  }
};

export const partyStatus = (viewed: number, status: string) => {
  if(status){
    return status;
  }else if(viewed){
    return "Viewed";
  }
};

// FILTER DOCUMENTS INCLUDING SUB FILES VIA SEARCH
export const searchByName = (documents: any[], query: string) => {
  const filteredData = documents.filter(item => {
    const parentNameMatch = item.name.toLowerCase().includes(query.toLowerCase());

    // Check if any name in the child array matches the query
    const childArrayMatch = (item?.children ?? []).some((child: any) => child.name.toLowerCase().includes(query.toLowerCase()));

    return parentNameMatch || childArrayMatch;
  });

  return filteredData.map(item => {
    // If there is a match in the child array, filter the child array
    if (item.children && item?.children.some((child: any) => child.name.toLowerCase().includes(query.toLowerCase()))) {
      const filteredChildArray = item.children.filter((child: any) => child.name.toLowerCase().includes(query.toLowerCase()));
      return { ...item, children: filteredChildArray };
    } else {
      return item;
    }
  });
};

// FILTER MEMBERS BY GIVEN NAME
export const searchByGivenName = (documents: any[], query: string) => {
  const filteredData = documents.filter(item => {
    const name = item.name || item.givenName || "";
    const parentNameMatch = name.toLowerCase().includes(query.toLowerCase());

    // Check if any name in the child array matches the query
    const childArrayMatch = (item?.children ?? []).some((child: any) => child.givenName.toLowerCase().includes(query.toLowerCase()));

    return parentNameMatch || childArrayMatch;
  });

  return filteredData.map(item => {
    // If there is a match in the child array, filter the child array
    if (item.children && item?.children.some((child: any) => child.givenName.toLowerCase().includes(query.toLowerCase()))) {
      const filteredChildArray = item.children.filter((child: any) => child.givenName.toLowerCase().includes(query.toLowerCase()));
      return { ...item, children: filteredChildArray };
    } else {
      return item;
    }
  });
};

// REMOVE ITEM FROM DASHBOARD
export const dashboardItemRemove = (array: any[], id: string) => {
  return _.reduce(array, (result: any, value: any) => {
    let children: any = value.children;
    if(!_.isEmpty(children)){
      children = children.filter((item: any) => item.id !== id);
    }

    if(value.id !== id){
      result.push({...value, children});
    }

    return result;
  },[]);
};

// MY TEAMS FILTER BY ROLE
export const getFilteredTeamsByRole = (teams: any[], role: string) => {
  if(role === "All"){ 
    return teams;
  } else{
    return _.filter(teams, (i: any) => i.role === role);
  }
};

// MY TEAMS FILTER BY ROLE
export const getFilteredDocumentByTeam = (documents: any[], tab: string) => {
  if(tab === "Team Documents"){
    return _.filter(documents, (i: any) => i.isShareToTeam === true);
  }else{
    return documents;
  }
};

// CHECK USER DETAILS IF HAVE A NAME ELSE USE EMAIL NAME
export const getNameFromEmail = (user: any) => {
  if(user.givenName){
    return `${user.givenName || ""} ${user.lastName || ""}`.trim();
  }
  const match = user?.email?.match(/^([^@]+)@/);
  return match ? match[1] : user.email;
};

// CALCULATE PROMO DISCOUNT
export const calculatePromoDiscount = (cost: number, promo: any) => {
  if(promo?.type === "TRIAL_DAYS"){
    return 1;
  }else if(promo?.type === "PERCENTAGE_DISCOUNT"){
    return cost - (cost * promo?.percentageDiscount);
  }else if(promo?.type === "FIXED_DISCOUNT"){
    return cost - promo?.fixedDiscountAmount;
  }else{
    return cost;
  }
};